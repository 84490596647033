import React, { memo, ReactNode, RefObject } from 'react';

import TransitionInOut2, { TransitionInOut2Api } from '../TransitionInOut2';
import useAppShell from '~/app/components/NextApp/lib/useAppShell';
import Clickable, { ClickableOnClick } from '../Clickable';
import { toRgba } from '~/app/lib/utils/color';
import ChevronIcon from '../Icon/ChevronIcon';
import MenuIcon from '../Icon/MenuIcon';
import MoreIcon from '../Icon/MoreIcon';
import BackButton from '../BackButton';
import Gradient from '../Gradient';
import Divider from '../Divider';
import Box from '../Box';
import FadeOnMount from '../FadeOnMount';

export const PAGE_HEADER_HEIGHT = '6.8rem';

export const ICON_SIZE = '3.8rem';
const HORIZONTAL_PADDING = '1.4rem';

export type PageHeaderRenderRight = () => JSX.Element;

export interface PageHeaderProps {
  onMoreClick?: ClickableOnClick;
  renderRight?: PageHeaderRenderRight;
  renderLeft?: () => JSX.Element;
  withGradient?: boolean;
  gradientApiRef?: RefObject<TransitionInOut2Api>;
  gradientIsVisibleInitial?: boolean;
  withMenuButton?: boolean;
  withBackButton?: boolean;
  backHref?: string;
  children?: ReactNode;
  backgroundColor?: string;
}

const PageHeader = memo<PageHeaderProps>(
  ({
    onMoreClick,
    renderRight,
    renderLeft,
    withGradient,
    gradientApiRef,
    gradientIsVisibleInitial,
    children,
    withMenuButton = false,
    withBackButton = true,
    backgroundColor = '#000',
    backHref,
  }) => {
    const { openAppDrawer } = useAppShell();

    const renderRightInternal = () => {
      if (onMoreClick) {
        return (
          <Clickable
            testId="actionMenuButton"
            onClick={onMoreClick}
            title="Open actions menu"
            centerContent
            fullWidth
            fullHeight
          >
            <MoreIcon size="1em" />
          </Clickable>
        );
      }

      if (renderRight) {
        return renderRight();
      }
    };

    return (
      <Box
        tag="header"
        flexBox
        positionRelative
        height={PAGE_HEADER_HEIGHT}
        fullWidth
        alignCenter
        pointerEvents="none"
        zIndex={1}
      >
        {withGradient !== undefined && (
          <TransitionInOut2
            isVisibleInitial={gradientIsVisibleInitial}
            apiRef={gradientApiRef}
            coverParent
            zIndex={-1}
            bottom="-20%"
          >
            <Gradient
              from={toRgba(backgroundColor, 0.65)}
              to={toRgba(backgroundColor, 0)}
              coverParent
            />
          </TransitionInOut2>
        )}
        <Box
          positionAbsolute
          top={0}
          left={HORIZONTAL_PADDING}
          fullHeight
          pointerEvents="all"
        >
          <Box flexRow fullHeight positionRelative alignCenter>
            {(() => {
              if (renderLeft) {
                return renderLeft();
              }

              return (
                <>
                  {withBackButton && (
                    <BackButton href={backHref}>
                      <Box
                        flexRow
                        fullHeight
                        centerContent
                        margin={`0 0 0 ${
                          withMenuButton ? '-1.2rem' : '-.2rem'
                        }`}
                      >
                        <ChevronIcon
                          direction="left"
                          opacity={withMenuButton ? 0.5 : 0.8}
                          size="2rem"
                        />
                        {withMenuButton && (
                          <Divider
                            direction="vertical"
                            margin="0 .2rem 0 -.2rem"
                            height="50%"
                            opacity={0.2}
                          />
                        )}
                      </Box>
                    </BackButton>
                  )}
                  {withMenuButton && (
                    <FadeOnMount>
                      <Clickable
                        onClick={openAppDrawer}
                        title="Open main menu"
                        centerContent
                        fullWidth
                        fullHeight
                        testId="sideNavButton"
                      >
                        <MenuIcon size={ICON_SIZE} />
                      </Clickable>
                    </FadeOnMount>
                  )}
                </>
              );
            })()}
          </Box>
        </Box>
        <Box
          centerContent
          style={{
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            textAlign: 'center',
            contain: 'strict',
          }}
        >
          {children}
        </Box>
        <Box
          positionAbsolute
          top={0}
          right={HORIZONTAL_PADDING}
          fullHeight
          pointerEvents="all"
        >
          {renderRightInternal()}
        </Box>
      </Box>
    );
  }
);

export default PageHeader;
