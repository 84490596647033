import { useEffect } from 'react';

import { on } from '../../lib/utils/events';
import onceIdle from '../../lib/utils/onceIdle';

import { resolveServiceDataFromUrl } from '../getServiceDisplayData';
import { useTracker } from './useTracker';

/**
 * Tracks low-level UI event tracking on clickable elements.
 *
 * This allows us to retrospectively track usage of certain
 * components without the need for custom events for every
 * user flow.
 */
export const useClickTracking = () => {
  const { trackEvent } = useTracker();

  useEffect(() => {
    return on(
      document,
      'click',
      (event: MouseEvent) => {
        try {
          const el = (event.target as HTMLElement).closest(
            'a,button,select,textarea'
          );

          if (!el) return;

          // don't interrupt/block user interaction
          setTimeout(() => {
            onceIdle(() => {
              const testId = el.getAttribute('data-testid') || undefined;
              const id = el.id || undefined;
              const destination = (el as HTMLAnchorElement).href;

              trackEvent({
                type: 'click',
                href: destination || undefined,
                destinationPlatform: destination
                  ? resolveServiceDataFromUrl(destination).name
                  : undefined,
                id,
                testId,
                text: el.textContent || undefined,
                tag: el.localName,
                className: el.className,
              });
            });
          });
        } catch {}
      },
      // Bind to 'capture' phase so this callback fires before all others,
      // if the callback was set on 'bubble' phase then if may be blocked
      // if event.stopPropagation() is called further down the dom tree.
      true
    );
  }, []);
};
