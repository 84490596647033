import toExternalIcon from '../toExternalIcon';
import url from '../sprite.external.svg';

const TikTokIcon = toExternalIcon(
  {
    url,
    targetId: 'tikTok',
  },
  { margin: '0 -.13em' }
);

export default TikTokIcon;
