import ThemeContext from '~/app/lib/theme/ThemeContext';
import { useContext } from 'react';
import themes from '~/app/lib/theme';

const useTheme = () => {
  const themeType = useContext(ThemeContext);
  return themes[themeType];
};

export default useTheme;
