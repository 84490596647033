import React from 'react';

import withSpacing, { WithSpacingProps } from '~/app/lib/hocs/withSpacing';

const DASHED_SIZE = 1;
const SOLID_SIZE = 1;
const DEFAULT_COLOR = '#999';

type DividerDirectionVertical = 'vertical';
type DividerDirectionHorizontal = 'horizontal';

const HORIZONTAL: DividerDirectionHorizontal = 'horizontal';
const VERTICAL: DividerDirectionVertical = 'vertical';

interface DividerProps extends WithSpacingProps {
  style?: React.CSSProperties;
  direction?: DividerDirectionVertical | DividerDirectionHorizontal;
  className?: string;
  isDashed?: boolean;
  opacity?: number;
  color?: string;
}

const Divider = withSpacing<DividerProps>(
  ({
    style,
    direction = HORIZONTAL,
    className,
    isDashed,
    opacity,
    color = DEFAULT_COLOR,
  }) => {
    switch (direction) {
      case HORIZONTAL:
        // dashed
        if (isDashed) {
          return (
            <div className={className} style={style}>
              <svg style={{ width: '100%', height: DASHED_SIZE, opacity }}>
                <line
                  strokeDasharray="2, 4"
                  x1="0%"
                  y1="1"
                  x2="100%"
                  y2="1"
                  style={{ stroke: color, strokeWidth: DASHED_SIZE }}
                />
              </svg>
            </div>
          );
        }

        // solid
        return (
          <div className={className} style={style}>
            <div
              style={{
                height: SOLID_SIZE,
                background: color,
                opacity,
              }}
            />
          </div>
        );

      case VERTICAL:
        return (
          <div
            className={className}
            style={{
              ...style,
              width: SOLID_SIZE,
              background: color,
              opacity,
            }}
          />
        );
      default:
        return null;
    }
  }
);

export default Divider;
