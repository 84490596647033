import { FC } from 'react';
import toIcon from './toIcon';

/**
 * Loads an external svg using <use> tag.
 *
 * We use this for service icons that aren't used very often to avoid
 * inlining the svg code into ItemPage bundles and gradually bloating
 * the app.
 *
 * Pros:
 *
 * - Inherits fill/stroke/currentColor
 * - Loads faster (before react hydration)
 * - Works without JS
 *
 * Cons:
 *
 * - Doesn't work for images on another domain (https://bugs.chromium.org/p/chromium/issues/detail?id=470601)
 * - Requires an id on the outer <svg>
 */
const toExternalIcon = (
  {
    url,
    targetId,
  }: {
    url: string;
    targetId: string;
  },
  baseStyle?: { margin: string }
) => {
  const ExternalIcon: FC<{
    style: React.CSSProperties;
    className?: string;
  }> = ({ style, ...props }) => {
    return (
      <svg {...props} style={{ ...baseStyle, ...style }}>
        <use href={`${url}#${targetId}`}></use>
      </svg>
    );
  };

  return toIcon(ExternalIcon);
};

export default toExternalIcon;
