import { FC } from 'react';

import Clickable, { ClickableProps } from '../Clickable';
import { useAppRouter } from '~/app/lib/router2';

type BackButtonProps = {
  href?: string;
};

const BackButton: FC<BackButtonProps> = ({ children, href }) => {
  const router = useAppRouter();

  const canGoBack = router.canGoBack() || href;
  if (!canGoBack) return null;

  const props: Omit<ClickableProps, 'children'> = {
    href: undefined,
    onClick: undefined,
  };

  if (href) props.href = href;
  else props.onClick = () => router.back();

  return (
    <Clickable {...props} testId="backButton">
      {children}
    </Clickable>
  );
};

export default BackButton;
